import convert from 'color-convert';

class Colours {
    constructor() {
        this.x = 0;
        this.y = 0;
        this.z = 50;

        this.dark = false;

        this.current = undefined;
        this.currentHex = undefined;

        this.inputHex = document.querySelector('input#colorHex');
        this.inputName = document.querySelector('input#colorName');

        this.canvas = document.createElement('canvas');
        this.canvas.width = 16;
        this.canvas.height = 16;
        this.ctx = this.canvas.getContext('2d');

        this.link = document.createElement('link');
        this.link.type = 'image/x-icon';
        this.link.rel = 'shortcut icon';
        document.querySelector('head').appendChild(this.link);
    }

    init() {
        document.addEventListener('mousemove', this.throttle((e) => {
            this.x = e.clientX;
            this.y = e.clientY;
            this.setColor();
        }, 100));
        document.addEventListener('wheel', (e) => {
            const deltaY = (e.deltaY < 0) ? -30: 30;
            this.z = Math.max(0, Math.min(100, this.z + deltaY / 10));
            this.setColor();
        });
        this.setColor();
    };

    setColor(r = null, g = null, b = null) {
        let hex;
        if (r != null) {
            hex = '#' + convert.rgb.hex(r, g, b);
            this.current = convert.rgb.hsl(r, g, b);
        } else {
            const h = this.x / window.innerWidth * 360;
            const s = 100 - (this.y / window.innerHeight * 100);
            const l = this.z;
            this.current = [h, s, l];
            hex = '#' + convert.hsl.hex(h, s, l);

            const [r, g, b] = convert.hsl.rgb(h, s, l);
            const colorRGB = {r: r / 255, g: g / 255, b: b / 255};

            const [maxColor, maxColor2] = this.parseResult(window.trainedNN(colorRGB));
            this.inputName.value = `${maxColor} / ${maxColor2}`;
        }
        this.currentHex = hex;
        this.ctx.fillStyle = hex;
        this.ctx.fillRect(0, 0, 16, 16);
        this.link.href = this.canvas.toDataURL("image/x-icon");
        document.title = hex;

        if (this.dark && this.current[2] >= 40) {
            this.dark = false;
        } else if (!this.dark && this.current[2] < 40) {
            this.dark = true;
        }
        document.body.style.backgroundColor = hex;
        this.inputHex.value = hex;

    }

    parseResult(datas) {
        let max = 0;
        let maxColor = "";
        let maxColor2 = "";
        for (let data in datas) {
            if (datas.hasOwnProperty(data)) {
                if (datas[data] > max) {
                    maxColor2 = maxColor;
                    max = datas[data];
                    maxColor = data;
                }
            }
        }
        return [maxColor, maxColor2];
    }

    throttle(func, limit) {
        let inThrottle;
        return function () {
            const args = arguments;
            const context = this;
            if (!inThrottle) {
                func.apply(context, args);
                inThrottle = true;
                setTimeout(() => inThrottle = false, limit);
            }
        };
    }

}

export default Colours;

import Colours from "./Colours";

class ViewManager {

    constructor() {
        this.loadingScreen = document.getElementById('loading-screen');
        this.welcomeScreen = document.getElementById('welcome-screen');
        this.hud = document.getElementById('hud');
    }

    show(element) {
        element.classList.remove('hidden');
    }

    hide(element) {
        element.classList.add('hidden');
    }

    showWelcomeScreen() {
        this.hide(this.loadingScreen);
        this.show(this.welcomeScreen);
        this.welcomeScreen.querySelector('.button').addEventListener('click', () => {
            this.showColorsScreen();
        });

    }

    showColorsScreen() {
        this.hide(this.welcomeScreen);
        this.show(this.hud);
        document.body.classList.add('colors');
        const colours = new Colours();
        colours.init();
    }

}

export default ViewManager;

import '../styles/index.scss';
import ViewManager from "./ViewManager";


const interval = setInterval(() => {
    if (window.trainedNN) {
        window.clearInterval(interval);
        const viewManager = new ViewManager();
        viewManager.showWelcomeScreen();
    }
}, 500);






